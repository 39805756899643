import React from 'react';
import Link from '../../components/atoms/Link/Link';
import GiftCertificatesTab from '../../components/organisms/GiftCertificatesTab/GiftCertificatesTab';
import Layout from '../../components/organisms/Layout/Layout';
import Seo from '../../components/organisms/Seo/Seo';

import { Box, Text } from '@chakra-ui/react';
import * as styles from './giftCertificates.module.css';

const Title = ({ children }) => (
  <div className={styles.redeemTitle}>{children}</div>
);
const Description = ({ children }) => (
  <li className={styles.redeemDescription}>{children}</li>
);

const RedeemGiftCertificate = () => (
  <div className='no-bottom-content' >
    <Title>To redeem a gift certificate follow the simple steps below.</Title>
    <Box as='ul' listStyleType={'decimal'} color={'#4B5661'}>
      <Description>
        You need your unique gift certificate code, which is part of the gift
        certificate that was emailed to you as an attachment. It will look
        something like Z50-Y6K-COS-402.
      </Description>
      <Description>
        Browse the store and add items to your cart as you normally would.
      </Description>
      <Description>
        Click the <Link url="/cart">
          <Text as={'span'} textDecor={'underline'} color={'#006E95'}>'View Cart'</Text>
  
          </Link> link to view the contents
        of your shopping cart.
      </Description>
      <Description>
        Type your gift certificate code in to the 'Redeem Gift Certificate' box
        and click 'Go'
      </Description>
    </Box>
  </div>
);

const RedeemGiftCertificatePage = () => {
  return (
    <Layout>
      <Seo title="Redeem Gift Certificates" />

      <GiftCertificatesTab pageId={'redeem-gift-certificate'}>
    
          
          <RedeemGiftCertificate />
      </GiftCertificatesTab>
    </Layout>
  );
};

export default RedeemGiftCertificatePage;
